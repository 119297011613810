@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;700&family=Montserrat:wght@400;700;900&family=Silkscreen&display=swap');
/* import script for the cursive font */
@import url('https://fonts.googleapis.com/css2?family=Playwrite+US+Trad:wght@100..400&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'JetBrains Mono', monospace;
}

::-webkit-scrollbar {
    display: none;
}

:root {
    --black: #121212;
    --slate-black: #191919;
    --slate-cement: #212121;
    --slate-powder: #515151;
    --slate-grey: #696969;

    --white-primary: #ffffff;
    --white-secondary: #dedede;

    --accent: #e7931f;

    --tag-red: #dc2626;
    --tag-yellow: #eab308;
    --tag-blue: #06b6d4;
    --tag-green: #10b981;
    --tag-purple: #a855f7;
    --tag-stone: #a8a29e;
}

::selection {
    background-color: var(--white-primary);
    color: var(--slate-black);
}

body {
    overflow-x: hidden;
}

.white {
    color: var(--white-secondary);
}

.grey {
    color: var(--slate-grey);
}

.fullScreen {
    width: 100vw;
    height: 100vh;
}

.titleCursive {
    font-family: 'Playwrite US Trad', cursive;
    font-size: 3em;
    font-optical-sizing: auto;
    color: var(--slate-powder);
}

/* responsive design for titleCursive */
@media screen and (max-width: 768px) {
    .titleCursive {
        font-size: 3em;
        height: 20vh;
    }

}

/* --------------------------------------- home page ---------------------------------------  */

.homepage {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    font-size: 5em;
    font-weight: 999;

    background: var(--black);

    width: 100vw;
}

.homeText {
    color: var(--white-primary);
    text-align: right;
    font-family: 'JetBrains Mono', monospace;
    z-index: 10;
}

.homeKavinImg {
    width: 30vw;
    aspect-ratio: 1/1;

    transition: 300ms ease;
    border-radius: 30px;
    box-shadow: 0px 0px 60px var(--slate-powder);
    transition: all 250ms ease-in-out;
    z-index: 10;

}

.homeKavinImg:hover {
    box-shadow: 0px 0px 60px var(--slate-grey);
    z-index: 10;

}

.homeHey {
    font-size: 0.2em;
    color: var(--slate-grey);
    z-index: 10;
}

/* responsive design for homepage */
@media screen and (max-width: 768px) {
    .homepage {
        flex-direction: column;
        z-index: 10;

    }

    .homeText {
        z-index: 10;
        text-align: center;
    }

    .homeKavinImg {
        width: 60vw;
        z-index: 10;
    }
}

.mazePage {
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--black);
    position: relative;
    overflow: hidden;
}

.mazeImage {
    position: absolute;
    top: 0;
    right: 0;

    background-size: contain;
    z-index: 0;

    opacity: 0.5;
}

.mazeContent {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    color: var(--white-primary);
    font-size: 4em;
    font-weight: 999;

    z-index: 1;

    width: 45vw;
}

/* responsive design for maze page */
@media screen and (max-width: 768px) {
    .mazeContent {
        font-size: 3em;
        width: 100vw;
        position: absolute;
        transform: rotate(90deg);
    }
}

.mazeSpillImage {
    position: absolute;
    top: -100vh;
    right: 0;

    opacity: 0.4;
}

.githubPage {
    background: var(--black);

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    position: relative;
}

.githubContent {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;

    width: 100vw;
    height: 100vh;
}

.githubCard {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;

    z-index: 1;

    width: 40vw;
    height: 50vh;
    padding: 2em;

    background: radial-gradient(var(--slate-black), var(--slate-cement));
    border-radius: 20px;
    border: 1px solid var(--slate-powder);
    transition: all 200ms ease-in-out;
}

.githubCard:hover {
    border: 1px solid var(--slate-grey);
}

.githubCardTop {
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.githubUserName {
    font-size: 2em;
    color: var(--white-primary);
    font-weight: 600;
}

.githubUserId {
    font-size: 1.5em;
    color: var(--slate-grey);
    font-weight: 600;
}

.githubCardLink {
    font-size: 1.5em;
    font-weight: 501;
    color: var(--white-primary);
}

.githubKavinImg {
    width: 200px;
    aspect-ratio: 1/1;
    border-radius: 50vh;
}

.githubText {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;

    z-index: 1;
}

.githubText>p {
    font-size: 3.5em;
    color: var(--white-primary);
    font-weight: 600;
}

/* github Page responsive design */
@media screen and (max-width: 768px) {
    .githubContent {
        flex-direction: column;
    }

    .githubCard {
        width: 80vw;
        height: 40vh;

        font-size: 0.8em;
    }

    .githubUserName,
    .githubUserId {
        text-align: right;
    }

    .githubText {
        align-items: flex-start;
    }

    .githubKavinImg {
        width: 100px;
    }

    .githubText>p {
        font-size: 2em;
    }
}

.skillsPage {
    background: var(--black);

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    position: relative;
}

.skillsSkillBoxesContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    width: 100vw;
}

.skillsText {
    font-size: 5em;
    font-weight: 600;
    color: var(--white-primary);
    position: absolute;
    z-index: 2;
    bottom: 0;
}

.skillsBox {
    width: 20vw;
    height: 40vh;

    background: var(--slate-black);
    color: var(--white-primary);

    border-radius: 20px;
    padding: 2em;
    transition: 200ms all ease-in-out;
    border: 1px solid var(--slate-black);

}

.skillsBox:hover {
    border: 1px solid var(--slate-powder);
}

.skillsBoxTitle {
    font-size: 2em;
    font-family: 'JetBrains Mono', monospace;
}

.skillsBoxContent {
    margin-left: 20%;
    font-family: 'JetBrains Mono', monospace;
}

/* skills page responsive design */
@media screen and (max-width: 768px) {

    .skillsPage {
        height: auto;
    }

    .skillsSkillBoxesContainer {
        flex-direction: column;
        height: auto;
    }

    .skillsBox {
        width: 80vw;
        height: 20vh;

        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        margin: 20px;
        padding: 20px;
    }

    .skillsText {
        font-size: 1.5em;
    }

    .skillsBoxTitle {
        font-size: 1.5em;
    }

    .skillsBoxContent {
        margin-left: 10%;
    }
}

.projectCard {
    width: 28vw;
    height: 25vh;

    background: var(--slate-black);
    color: var(--white-primary);

    border-radius: 20px;
    padding: 2em;
    transition: 200ms all ease-in-out;
    border: 1px solid var(--slate-black);

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.projectCard:hover {
    border: 1px solid var(--slate-powder);
}

.projectCardHeaders {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 50px;
}

.projectCardLink {
    font-size: 0.8em;
    font-weight: 501;
    color: var(--slate-grey);
}

.tagRed {
    color: var(--tag-red);
}

.tagYellow {
    color: var(--tag-yellow);
}

.tagBlue {
    color: var(--tag-blue);
}

.tagGreen {
    color: var(--tag-green);
}

.tagPurple {
    color: var(--tag-purple);
}

.tagStone {
    color: var(--tag-stone);
}

.projectsPage {
    background-color: var(--black);
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.projectsContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
}

.projectsRow {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    width: 100vw;
}

/* projects page responsive design */
@media screen and (max-width: 768px) {
    .projectsPage {
        height: auto;
        background-color: var(--black);
    }

    .projectsContainer {
        flex-direction: column;
        height: auto;
    }

    .projectCard {
        width: 80vw;
        height: 20vh;

        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;

        margin: 20px;
        padding: 20px;
    }

    .projectsRow {
        flex-direction: column;
    }

    .projectCardHeaders {
        flex-direction: row;
        gap: 10px;
    }
}

.footerPage {
    background-color: var(--black);
    /* position: relative;     */

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.kavinWindowImg {
    height: 80vh;
    width: auto;
    z-index: 2;
}

.languageBox {
    width: 15vw;
    height: 10vh;
}

.languageBoxTitle {
    font-size: 1.3em;
    font-family: 'JetBrains Mono', monospace;
}

.languageBoxContent {
    margin-left: 20%;
    font-family: 'JetBrains Mono', monospace;
}


.footerContent {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;

    width: 60vw;
    height: 100%;
    z-index: 2;
}

.footerIcons {
    width: 30px;
    height: auto;
    cursor: pointer;
}

.footerTitle {
    font-size: 1.5em;
    color: var(--slate-grey);
    font-weight: 700;

    margin-bottom: 10px;
}

.footerDevName {
    font-size: 3em;
    color: var(--white-primary);
    font-family: "JetBrains Mono", sans-serif;
}

.footerSocials {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 80px;
    margin-top: 10px;
}

.footerText {
    color: var(--white-secondary);
    font-family: "JetBrains Mono", sans-serif;
    font-size: 1em;
}

.footerLanguages {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.footerEmail {
    color: var(--slate-grey);
}

/* footer responsive design */
@media screen and (max-width: 768px) {
    .footerPage {
        flex-direction: column;
    }

    .footerContent {
        padding-left: 20px;
        width: 100vw;
    }

    .footerDevName {
        font-size: 2em;
    }

    .footerIcons {
        width: 20px;
    }

    .languageBox {
        width: 80vw;

        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }


    .kavinWindowImg {
        display: none;
    }

    .footerLanguages {
        width: 100vw;
        /* align-items: flex-start; */
        margin: 0;
    }
}



/* 
.creativityPage {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--black);
    position: relative;

    /* overflow: hidden;
}

.creativityText {
    font-size: 5em;
    font-weight: 600;
    color: var(--white-primary);
    z-index: 2;
    position: absolute;
    top: 0;
}

.creativityBulb {
    width: 30vw;
    height: auto;
    position: absolute;

    left: 15%;
    transition: all 200ms ease-in-out;
    z-index: 1;
}

.creativityHouse {
    height: 85vh;
    width: auto;
    position: absolute;
    right: 0%;
    top: 20%;
    transition: all 200ms ease-in-out;
    z-index: 1;
}

.creativityBulb,
.creativityHouse {
    border: 1px solid var(--slate-cement);
    border-radius: 5px;
}

.creativityBulb:hover,
.creativityHouse:hover {
    border: 1px solid var(--slate-powder);
}

@media screen and (max-width: 768px) {
    .creativityBulb {
        scale: 0.5;
        z-index: 100;
        top: 10px;
        right: 10px;
    }

    .creativityHouse {
        scale: 0.7;
        top: 30px;
        right: 10px;
    }

    .creativityText {
        font-size: 1.5em;
    }
} */